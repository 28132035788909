import { Fragment } from 'react';

import { AvatarImg } from '@playbooks/interface/avatars';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Hr, Span } from '@playbooks/interface/html';
import { NavBtn, NavItem, NavLink, NavList } from '@playbooks/interface/navs';
import { Slide, SlideBody, SlideHeader } from '@playbooks/interface/slides';
import { AccountSlideFooter } from 'components/account/account-slide-footer';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { useSession } from 'contexts';

const TeamSlideNav = ({ team, rootLink, open, setOpen, onLogout }) => {
	const session = useSession();

	// Methods
	const onOpen = () => setOpen(!open);

	// Render
	return (
		<Fragment>
			<Slide open={open} placement='right' onClose={onOpen}>
				<SlideHeader onClose={onOpen}>
					<OwnerAvatar size='md' ownerType='User' owner={team} />
				</SlideHeader>
				<SlideBody spacing='p-2'>
					<NavList space='space-y-2'>
						<NavItem>
							<NavLink prevIcon='home' href='/'>
								Home
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink prevIcon='globe' href='/browse'>
								Browse
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink prevIcon='magnifying-glass' href='/search'>
								Search
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink prevIcon='plus' href='/submit'>
								Submit
							</NavLink>
						</NavItem>

						<NavItem spacing='py-1'>
							<Hr />
						</NavItem>

						<NavItem>
							<NavLink prevIcon='circle-user' href={rootLink}>
								Profile
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink prevIcon='cart-shopping' href='/orders'>
								Orders
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink prevIcon='arrow-down-to-line' href='/downloads'>
								Downloads
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink prevIcon='bell' href='/notifications'>
								Notifications
							</NavLink>
							<NavItem>
								<NavLink prevIcon='gear' href='/settings'>
									Settings
								</NavLink>
							</NavItem>
						</NavItem>

						<NavItem spacing='py-1'>
							<Hr />
						</NavItem>

						<NavItem>
							<NavLink prevIcon='toggle-on' href='/accounts'>
								Switch Account
							</NavLink>
						</NavItem>

						<NavItem spacing='py-1'>
							<Hr />
						</NavItem>

						{session.user.role === 'admin' && (
							<NavItem>
								<NavLink prevIcon='arrow-right-to-bracket' href='/admin'>
									View Admin
								</NavLink>
							</NavItem>
						)}
						<NavItem>
							<NavBtn prevIcon='arrow-right-from-bracket' onClick={onLogout}>
								Logout
							</NavBtn>
						</NavItem>
					</NavList>
				</SlideBody>
				<AccountSlideFooter />
			</Slide>
			<AccentBtn size='icon' onClick={onOpen}>
				<Span>
					<AvatarImg size='w-4 h-4' src={team.thumbnail} border='border' position='absolute' inset='bottom-1 right-1' />
					<AvatarImg size='w-7 h-7' src={session.user.thumbnail} />
				</Span>
			</AccentBtn>
		</Fragment>
	);
};

export { TeamSlideNav };
