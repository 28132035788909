import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { FadIcon, FarIcon } from '@playbooks/interface/icons';
import { NavbarBrand } from '@playbooks/interface/navbars';
import { NavItem, NavLink, NavList } from '@playbooks/interface/navs';
import { Slide, SlideBody, SlideHeader } from '@playbooks/interface/slides';
import { AccountSlideFooter } from 'components/account/account-slide-footer';
import { useTheme } from 'contexts';

const GuestSlideNav = ({ open, setOpen }) => {
	const theme = useTheme();

	// Computes
	const BrandLogo = '/branding/playbooks-logo.svg';
	const BrandLogoDark = '/branding/playbooks-logo-dark.svg';

	// Methods
	const onOpen = () => setOpen(!open);

	// Render
	return (
		<Fragment>
			<Slide open={open} placement='right' onClose={onOpen}>
				<SlideHeader onClose={onOpen}>
					<NavbarBrand src={theme.isDark ? BrandLogoDark : BrandLogo} width='w-36' />
				</SlideHeader>

				<SlideBody spacing='p-2'>
					<NavList space='space-y-2'>
						<NavItem>
							<NavLink href='/login'>Login</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/register'>Register</NavLink>
						</NavItem>

						<NavItem border='border-b' spacing='m-2' />

						<NavItem>
							<NavLink href='/about'>About</NavLink>
						</NavItem>

						<NavItem>
							<NavLink href='/pricing'>Pricing</NavLink>
						</NavItem>

						<NavItem>
							<NavLink href='/faq'>Blog</NavLink>
						</NavItem>

						<NavItem>
							<NavLink href='/help'>Help Center</NavLink>
						</NavItem>

						<NavItem>
							<NavLink href='/support'>Support</NavLink>
						</NavItem>

						<NavItem border='border-b' spacing='m-2' />
					</NavList>
				</SlideBody>
				<AccountSlideFooter />
			</Slide>
			<BorderBtn size='xs' span={{ display: 'flex-middle' }} onClick={onOpen}>
				<FarIcon icon='bars' fontSize='text-base' />
				<FadIcon icon='circle-user' fontSize='text-2xl' />
			</BorderBtn>
		</Fragment>
	);
};

export { GuestSlideNav };
