import { Small } from '@playbooks/interface/fonts';
import { Div } from '@playbooks/interface/html';
import { TextLink } from '@playbooks/interface/links';
import { SlideFooter } from '@playbooks/interface/slides';

const AccountSlideFooter = () => {
	// Render
	return (
		<SlideFooter display='flex-between' space='space-x-4'>
			<Small>{new Date().getFullYear()} © Playbooks, Inc</Small>
			<Div display='flex-end' space='space-x-4'>
				<TextLink size='' fontSize='text-xs' href='/terms'>
					Terms
				</TextLink>
				<TextLink size='' fontSize='text-xs' href='/privacy'>
					Privacy
				</TextLink>
			</Div>
		</SlideFooter>
	);
};

export { AccountSlideFooter };
