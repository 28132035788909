import { Fragment, useEffect } from 'react';

import { AccentBtn } from '@playbooks/interface/buttons';
import { AccentLink } from '@playbooks/interface/links';
import { NavbarList } from '@playbooks/interface/navbars';
import { NavItem } from '@playbooks/interface/navs';
import { AccountDrop } from 'components/account/account-drop';
import { AccountSlideNav } from 'components/account/account-slide-nav';
import { NotificationNavLink } from 'components/notification/notification-nav-link';
import { useSession } from 'contexts';

const AppNavbarList = ({ open, setOpen }) => {
	const session = useSession();

	// Hooks
	useEffect(() => {}, []);

	// Methods
	const onToggle = () => setOpen(!open);

	// Render
	return (
		<Fragment>
			<NavbarList display='hidden lg:flex-end' spacing='pl-4'>
				{session.isAuthenticated() ? (
					<Fragment>
						<NavItem>
							<AccentLink size='icon' icon='home' alt='home link' href='/home' />
						</NavItem>
						<NavItem>
							<AccentBtn size='icon' icon='globe' alt='browse button' onClick={onToggle} />
						</NavItem>
						<NavItem>
							<NotificationNavLink />
						</NavItem>
						<NavItem>
							<AccentLink size='icon' icon='plus' alt='add link' href='/add' />
						</NavItem>
						<NavItem>
							<AccountDrop />
						</NavItem>
					</Fragment>
				) : (
					<Fragment>
						<NavItem>
							<AccentBtn size='icon' icon='globe' alt='browse button' onClick={onToggle} />
						</NavItem>
						<NavItem>
							<AccentLink size='icon' icon='plus' alt='add link' href='/add' />
						</NavItem>
						<NavItem>
							<AccountDrop />
						</NavItem>
					</Fragment>
				)}
			</NavbarList>
			<NavbarList display='block lg:hidden' spacing='pl-4'>
				<NavItem>
					<AccountSlideNav />
				</NavItem>
			</NavbarList>
		</Fragment>
	);
};

export { AppNavbarList };
