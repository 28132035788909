import { Fragment, useState } from 'react';

import { GuestSlideNav } from 'components/guest/guest-slide-nav';
import { TeamSlideNav } from 'components/team/team-slide-nav';
import { UserSlideNav } from 'components/user/user-slide-nav';
import { useAccount, useSession } from 'contexts';

const AccountSlideNav = () => {
	const [open, setOpen] = useState(false);
	const account = useAccount();
	const session = useSession();

	// Methods
	const onLogout = async () => {
		session.onLogout();
	};

	// Render
	return (
		<Fragment>
			{account.account.id ? (
				<Fragment>
					{account.type === 'User' && (
						<UserSlideNav
							user={account.account || {}}
							rootLink={account.rootLink}
							open={open}
							setOpen={setOpen}
							onLogout={onLogout}
						/>
					)}
					{account.type === 'Team' && (
						<TeamSlideNav
							team={account.account || {}}
							rootLink={account.rootLink}
							open={open}
							setOpen={setOpen}
							onLogout={onLogout}
						/>
					)}
				</Fragment>
			) : (
				<GuestSlideNav open={open} setOpen={setOpen} />
			)}
		</Fragment>
	);
};

export { AccountSlideNav };
